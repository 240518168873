import React from 'react';
import {
  createStyles,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { Typography } from '@cbreone/core-ui';
import { SurveyContact } from '../../types';
import InteractiveSurveyContactPreview from '../InteractiveSurveyContactPreview';

export type Props = {
  surveyContactList: SurveyContact[]
};

const useStyles = makeStyles((theme) => createStyles({
  root: {
    paddingLeft: '20px',
    backgroundColor: theme.palette.grey['400'],
  },
  header: {
    height: '50px',
    borderBottom: `1px solid ${theme.palette.info.main}`,
    marginBottom: '8px',
  },
  contactBox: {
    position: 'relative',
    width: '260px',
    [theme.breakpoints.between('sm', 'md')]: {
      width: '200px',
    },
    maxHeight: '160px',
    marginBottom: 'auto',
  },
  contactLegend: {
    '@media (min-width: 767px)': {
      position: 'absolute',
      margin: '-10px',
      top: '20px',
    },
  },
  contactName: {
    color: theme.palette.grey['800'],
    fontSize: '8.5px',
    '@media (min-width: 767px)': {
      writingMode: 'vertical-lr',
      transform: 'scale(-1, -1)',
      marginTop: '31px',
    },
    '@media (max-width: 767px)': {
      paddingTop: '8px',
    },
  },
  contactCards: {
    backgroundColor: theme.palette.grey['400'],
    paddingLeft: '20px',
    '@media (max-width: 767px)': {
      // minHeight: '171px',
      width: '312px',
    },
  },
}));

const InteractiveSurveyContactPreviewList: React.FC<Props> = (
  { surveyContactList },
) => {
  const classes = useStyles();

  surveyContactList.sort((a, b) => (a.order || 0) - (b.order || 0));

  return (
    <Grid
      item container direction="row"
      justifyContent="flex-start" alignItems="center" className={classes.contactCards}>
      {
        surveyContactList.length > 0 && <Grid item className={classes.contactLegend}>
          <Typography variant="body4" className={classes.contactName}>
            Your CBRE Team
          </Typography>
        </Grid>
      }
      {
        surveyContactList.map(
          (contact, index) => <Grid item key={index} className={classes.contactBox}>
            <InteractiveSurveyContactPreview key={index} surveyContact={contact}>
            </InteractiveSurveyContactPreview>
          </Grid>,
        )
      }
    </Grid>
  );
};

InteractiveSurveyContactPreviewList.displayName = 'InteractiveSurveyContactPreviewList';
export default InteractiveSurveyContactPreviewList;
