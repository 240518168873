import {
  makeStyles, createStyles, Grid,
} from '@material-ui/core';
import React, { useRef } from 'react';
import { Survey } from '../../types';
import InteractiveCoverSummaryPreview from '../InteractiveCoverSummaryPreview';
import InteractiveSurveyContactPreviewList from '../InteractiveSurveyContactPreviewList';

const useStyles = makeStyles((theme) => createStyles({
  surveyHeight: {
    height: '100%',
  },
  coverSummary: {
    width: '100%',
  },
  coverImageBox: {
    flexGrow: 1,
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '100%',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    '@media (max-width: 767px)': {
      minHeight: '110vh',
      height: 'auto',
    },
  },
  coverImage: {
    height: 'inherit',
    objectFit: 'cover',
    width: '100%',
    '@media (max-width: 767px)': {
      width: '375px',
    },
  },
  coverImagePanel: {
    height: '100%',
  },
  contactName: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.grey['400'],
    height: '148px',
  },
  contactPanel: {
    position: 'absolute',
    bottom: '25px',
    zIndex: 88,
    // overflow: 'auto',
    flexWrap: 'wrap',
    display: 'flex',
    width: '100%',
    '@media (max-width: 767px)': {
      top: '550px',
      position: 'absolute',
    },
  },
  viewReport: {
    position: 'absolute',
    zIndex: 99,
    padding: '25px',
    background: theme.palette.grey[800],
    opacity: '0.95',
    alignItems: 'center',
    justifyContent: 'center',
    height: '140px',
  },
  viewReportText: {
    lineHeight: '21px',
    fontSize: '14px',
  },
  viewReportLink: {
    lineHeight: '20px',
    float: 'right',
    marginLeft: '5px',
    fontSize: '14px',
    color: 'white',
    textDecoration: 'none',
  },
  viewReportSeperator: {
    background: theme.palette.primary.light,
    width: '30px',
    padding: '1px',
  },
  viewReportLinkSec: {
    display: 'flex',
    alignItems: 'center',
    float: 'right',
  },
  container1: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  contactCards: {
    height: '150px',
    width: 'auto',
    overflow: 'auto',
    overflowX: 'hidden',
    maxWidth: '92%',
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '85%',
    },
    '@media (max-width: 767px)': {
      width: '312px',
      height: 'auto',
      left: '24px',
    },
  },
}));

const InteractiveCoverPreview: React.FC<Props> = (
  {
    survey,
    pageMode,
  },
) => {
  const classes = useStyles();
  const summaryCompEle = useRef<any>();
  const imgCompEle = useRef<any>();
  const coverPanel = useRef<any>();

  let coverImgURL = '/assets/images/office_exterior.jpg';
  if (survey && survey.coverBackgroundImage && survey.coverBackgroundImage.cloudfrontUrl) {
    coverImgURL = survey.coverBackgroundImage.cloudfrontUrl;
    coverImgURL += '?d=web';
  }

  return (survey && <Grid
    container direction="column" className={classes.surveyHeight}
    ref={coverPanel}>
    <Grid
       item container
       direction="row"
       className={classes.surveyHeight}
     >
      <Grid
       item container
       md={6} xl={6}
       className={classes.coverSummary}
       ref={summaryCompEle}
     >
        <Grid
          item container direction="column"
          justifyContent="center">
          <InteractiveCoverSummaryPreview survey={survey}></InteractiveCoverSummaryPreview>
        </Grid>
      </Grid>
      <Grid
        item container xs={6}
        md={6} xl={6} className={classes.coverImageBox}
        ref={imgCompEle}>
        <Grid
          item xs={12} md={12}
          xl={12} className={classes.coverImagePanel}>
          <img className={classes.coverImage} src={coverImgURL} alt="" />
        </Grid>
      </Grid>
    </Grid>
    <Grid
      item container direction="row"
      justifyContent="center" alignItems="center" className={classes.contactPanel} >
      <Grid
        item container direction="row"
        justifyContent="center" alignItems="center" className={classes.contactCards}>
        <InteractiveSurveyContactPreviewList surveyContactList={survey.contacts}>
        </InteractiveSurveyContactPreviewList>
      </Grid>
    </Grid>
  </Grid>
  );
};

export type Props = {
  survey: Survey;
  pageMode?: string;
  order: string;
  anchore?: object;
};

InteractiveCoverPreview.displayName = 'InteractiveCoverPreview';
export default InteractiveCoverPreview;
