import { SurveyPropertyFieldRule, PropertyFieldRulePatch } from '../types';
import { axios } from '../internal-lib';
import onSuccess from './onSuccess';
import { surveyServiceBaseURL } from '../config';

const updatePropertyFieldRules = (
  surveyId: string, propertyFieldRule: PropertyFieldRulePatch) =>
  axios.patch<SurveyPropertyFieldRule>(`${surveyServiceBaseURL}survey/propertyfieldrule/${surveyId}`, propertyFieldRule).then(onSuccess);

export default updatePropertyFieldRules;
