import React, { useRef, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles, Typography } from '@material-ui/core';
import { match } from 'react-router';
import { useHistory } from 'react-router-dom';
import { InteractiveLogin } from '../../types/interactive/login';
import { login } from '../../services/interactive/data-service';
import { RouteParams } from '../../routes/interactive.routes';
import BHSCAnalytics, { EVENT_TITLES, SC_EVENT } from '../../services/BHSCAnalytics';

type Props = {
  match: match;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    blxModal: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      width: 'max-content',
      maxHeight: '90%',
      padding: '24px 40px',
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '10px',
      border: '1px solid #d9e1e0',
      backgroundColor: '#fff',
      boxShadow: '0 1px 2px 0 rgb(170 183 178 / 60%)',
    },

    interactiveLogins: {
      height: '320px',
      width: '320px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px 0 rgba(43, 56, 54, 0.25)',
      padding: '0 15px',
      vertivalAlign: 'middle',
      justifyContent: 'center',
      alignItems: 'center',
      flex: '1',
      hr: {
        backgroundColor: '$secondary-01-hex',
        marginBottom: '10px',
        height: '1px',
        border: 'none',
      },
    },
    container: {
      textAlign: 'center',
    },

    passcodeInput: {
      height: '190px',
    },

    input: {
      width: '242px',
      height: '78px',
      marginBottom: '10px',
      borderRadius: '7px',
      border: '#edf6ff',
      backgroundColor: '#edf6ff',
      fontSize: '65px',
      letterSpacing: '14px',
      color: '#49a1ff',
      fontWeight: 'bold',
      textAlign: 'center',
      fontVariantNumeric: 'tabular-nums',
      mozFontFeatureSettings: 'tnum',
      webkitFontFeatureSettings: 'tnum',
      fontFeatureSettings: 'tnum',

      focus: {
        outline: 'none',
      },
    },

    correctInput: {
      color: theme.palette.common.white,
      backgroundColor: 'green',
      border: 'none',
    },

    correctMessage: {
      color: 'green',
    },

    invalidInput: {
      backgroundColor: '#fed87f',
      color: theme.palette.common.white,
      border: 'none',
    },

    invalidMessage: {
      color: '#fed87f',
    },
  }),
);

const InteractiveSurveyLogin: React.FC<Props> = ({ match: { params } }) => {
  const { surveyId } = params as RouteParams;
  const classes = useStyles();
  const [isValid, setIsValid] = React.useState(false);
  const [isIncorrect, setIsIncorrect] = React.useState(false);
  const DASH = '−';
  const inputRef = useRef<any>();
  const [passcode, setPasscode] = React.useState(DASH + DASH + DASH + DASH);
  const history = useHistory();
  const [cursor, setCursor] = React.useState(0);

  useEffect(() => {
    BHSCAnalytics.trackEvent(SC_EVENT, EVENT_TITLES.SC_INTERACTIVE_DELIVERABLE_VISIT, { surveyId, source: 'Link', startTime: new Date().toISOString });
  }, [surveyId]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.selectionEnd = cursor;
    }
  }, [inputRef, cursor]);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();

    let userPasscode = '';
    let cursorPosition = 0;
    for (let i = 0; i < 4; i += 1) {
      userPasscode += e.currentTarget.value[i] || DASH;
      if (e.currentTarget.value[i] && e.currentTarget.value[i] !== DASH) {
        cursorPosition += 1;
      }
    }

    setPasscode(userPasscode);
    setCursor(cursorPosition);

    const loginData: InteractiveLogin = {
      surveyId: surveyId!,
      passcode: userPasscode,
    };

    // if passcode is four non-dashes, send auth request
    if (/^[^−]{4}$/.test(userPasscode) && loginData.surveyId) {
      login(loginData)
        .then((response: any) => {
          const { interactiveToken } = response;
          if (response && interactiveToken) {
            localStorage.setItem('interactiveToken', interactiveToken);
            setIsValid(true);
            setIsIncorrect(false);
            setTimeout(() => history.push(`/${surveyId}`), 800);
          }
        })
        .catch((error) => {
          setIsValid(false);
          setIsIncorrect(true);
          setPasscode(DASH + DASH + DASH + DASH);
          setCursor(0);
        });
    }
  };

  const onInputEvent = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // don't move cursor for arrow clicks
    if (e.key === 'ArrowDown' || e.key === 'ArrowLeft'
    || e.key === 'ArrowRight' || e.key === 'ArrowUp') {
      e.preventDefault();
      inputRef.current.selectionEnd = cursor;
      inputRef.current.selectionStart = cursor;
    }
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLInputElement>) => {
    // don't move cursor for mouse clicks
    e.preventDefault();
    inputRef.current.selectionEnd = cursor;
    inputRef.current.selectionStart = cursor;
  };

  return (
    <>
      <div>
        <span className="cbre login-logo" />
      </div>
      <form
        className={clsx(classes.interactiveLogins, classes.blxModal)}
        method="post"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className={classes.container}>
          <Typography variant="h6">Enter Passcode</Typography>
          <hr />
          <Typography variant="body1">
            This document is locked.
            <br />
            Please enter the passcode below.
          </Typography>
          <div>
            <input
              className={clsx(
                classes.input,
                isValid && classes.correctInput,
                isIncorrect && classes.invalidInput,
              )}
              ref={inputRef}
              name="passcode"
              value={passcode}
              onChange={onChange}
              onKeyDown={onInputEvent}
              onMouseUp={handleMouseEvent}
              disabled={isValid}
            ></input>
            {isValid && (
              <Typography className={classes.correctMessage}>
                That&#39;s correct! Welcome!
              </Typography>
            )}
            {isIncorrect && (
              <Typography className={classes.invalidMessage}>
                Incorrect passcode.
              </Typography>
            )}
          </div>
          <Typography variant="body1">
            If you don&#39;t know the code, ask the person who sent you this
            link.
          </Typography>
        </div>
      </form>
    </>
  );
};

InteractiveSurveyLogin.displayName = 'InteractiveSurveyLogin';
export default InteractiveSurveyLogin;
