/* eslint-disable comma-dangle */
import React from 'react';
import ReactDOM from 'react-dom';

import App from './app/app';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
