import {
  Box,
  Drawer,
  List,
  Link as MUILink,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MenuIcon from '@material-ui/icons/Menu';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Property, SurveySection } from '../../types';
import * as mapUtil from '../../utilities/map';
import LinkTabItem from '../LinkTabItem';
import RedirectLink from '../RedirectLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropDownDown: {
      display: 'flex',
      backgroundColor: theme.palette.primary.dark,
      paddingLeft: 32,
      paddingRight: 0,
      fontSize: 14,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        color: theme.palette.primary.light,
      },
      '@media (max-width: 768px)': {
        paddingLeft: 8,
      },
      '@media (max-width: 767px)': {
        paddingLeft: 24,
        paddingTop: 20,
      },
    },
    dropDownUp: {
      display: 'flex',
      backgroundColor: theme.palette.primary.dark,
      paddingLeft: 32,
      paddingRight: 0,
      fontSize: 14,
      color: theme.palette.primary.light,
      textDecoration: 'underline',
      textUnderlinePosition: 'under',
      '&:hover': {
        color: theme.palette.primary.light,
      },
      '@media (max-width: 768px)': {
        paddingLeft: 8,
      },
      '@media (max-width: 767px)': {
        paddingLeft: 24,
      },
    },
    dropDownIconUp: {
      color: theme.palette.primary.light,
      transform: 'rotate(180deg)',
      transition: 'transform 150ms ease', // smooth transition
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    menu: {
      marginTop: 32,
      marginLeft: 30,
      '@media (max-width: 767px)': {
        '& .MuiPopover-paper': {
          left: '47px !important',
          right: 48,
          maxWidth: '100%',
        },
      },
    },
    drawerMenu: {
      '@media (max-width: 767px)': {
        '& .MuiPopover-paper': {
          top: '12.1em !important',
          left: '47px !important',
          right: 48,
          maxWidth: '100%',
        },
      },
    },
    menuItem: {
      font: 'Calibre-R',
      fontSize: 14,
    },
    serialNumber: {
      display: 'flex',
      backgroundColor: '#0976C3',
      borderRadius: 50,
      width: 20,
      height: 20,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      alignItems: 'center',
      fontSize: 12,
      justifyContent: 'center',
      paddingTop: 1,
      minWidth: '20px',
    },
    propertyName: {
      marginLeft: 14,
    },
    mobileView: {
      display: 'none',
      '@media (max-width: 767px)': {
        display: 'block',
      },
    },
    drawerSection: {
      backgroundColor: '#012A2D',
      width: 200,
    },
    disclaimer: {
      textDecoration: 'none',
      display: 'flex',
      position: 'absolute',
      top: 13,
      right: 16,
      font: 'Calibre-R',
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      '@media (max-width: 767px)': {
        display: 'none',
      },
      '&:hover': {
        color: theme.palette.primary.light,
        textDecoration: 'none',
      },
    },
    propertiesDropdown: {
      marginBottom: -12,
    },
    mobileDisclaimer: {
      textDecoration: 'none',
      paddingLeft: 24,
      display: 'flex',
      font: 'Calibre-R',
      paddingTop: '20px',
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    active: {
      color: theme.palette.primary.light,
      paddingLeft: 32,
      display: 'none',
      font: 'Calibre-R',
      fontSize: 14,
      textUnderlinePosition: 'under',
      '@media (max-width: 767px)': {
        display: 'flex',
      },
    },
    desktopSpace: {
      '@media (max-width: 768px)': {
        marginLeft: 5,
        padding: 5,
      },
      '@media (max-width: 767px)': {
        marginLeft: 'auto',
      },
    },
    desktopView: {
      display: 'block',
      '@media (min-width: 767px)': {
        display: 'none',
      },
    },
    rightAlign: {
      marginLeft: -5,
    },
    mobilePropertiesDropdown: {
      marginLeft: 'auto',
      paddingBottom: 29,
      width: 'auto',
      maxWidth: '182px',
      boxSizing: 'border-box',
      '& .MuiIconButton-label': {
        width: 'auto',
        maxWidth: '290px',
        boxSizing: 'border-box',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textAlign: 'initial',
        textOverflow: 'ellipsis',
        display: 'block',
      },
    },
  }),
);

type Props = {
  surveyId: string,
  sectionList: any,
  propertyList: any;
};

const SectionTabs: React.FC<Props> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { surveyId, sectionList, propertyList } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileopen, setMobileOpen] = React.useState(false);
  const [arrow, setArrow] = useState(false);
  const [mobileAnchorEl, setMobileAnchorEl] = React.useState(null);
  const [mobileArrow, setMobileArrow] = useState(false);
  const [drawerAnchorEl, setDrawerAnchorEl] = React.useState(null);
  const [drawerArrow, setDrawerArrow] = useState(false);
  const open = Boolean(anchorEl);
  const mobileOpen = Boolean(mobileAnchorEl);
  const drawerOpen = Boolean(drawerAnchorEl);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);
  const shortlisted = (localStorage.getItem('shortlisted') === 'true');

  window.addEventListener('resize', () => {
    setIsDesktop(window.innerWidth >= 768);
  });
  const handleClick = (event: any) => {
    setArrow(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setArrow(false);
    setAnchorEl(null);
    setDrawerAnchorEl(null);
    setMobileOpen(false);
  };
  const handleMobileClick = (event: any) => {
    setMobileArrow(true);
    setMobileAnchorEl(event.currentTarget);
  };

  const handleMobileClose = () => {
    setMobileArrow(false);
    setMobileAnchorEl(null);
  };

  const handleDrawerClick = (event: any) => {
    setDrawerArrow(true);
    setDrawerAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setDrawerArrow(false);
    setDrawerAnchorEl(null);
  };

  const goToProperty = (property: Property) => {
    history.push(`/${surveyId}/property/${property.id}`);
  };

  const SURVEY_SECTIONS = {
    COVER: 'cover',
    MARKET_MAP: 'market map',
    PROPERTY_COMPARISON: 'property comparison',
    PROPERTIES: 'properties',
    TOUR_SCHEDULE: 'tour schedule',
  };

  const shownSections = sectionList.filter(
    (section: SurveySection) => !section.isHidden,
  );

  const shownProperty = propertyList.filter(
    (property: Property) => !Object.prototype.hasOwnProperty.call(property, 'isHidden') || !property.isHidden,
  );

  const propertiesPresent = shownSections.some(
    (section: SurveySection) => section.name === SURVEY_SECTIONS.PROPERTIES,
  );

  const propertiesSectionIdx = propertiesPresent
    ? shownSections.findIndex(
      (section: SurveySection) => section.name === SURVEY_SECTIONS.PROPERTIES,
    )
    : shownSections.length;

  const SURVEY_SECTIONS_IDS = {
    COVER: 'cover',
    MARKET_MAP: 'market map',
    PROPERTY_COMPARISON: 'property comparison',
    PROPERTIES: 'properties',
    TOUR_SCHEDULE: 'tour schedule',
  };

  const sectionToComponentMap = {
    COVER: 'cover',
    MARKET_MAP: 'marketmap',
    PROPERTY_COMPARISON: 'comparison',
    TOUR_SCHEDULE: 'tour',
    PROPERTIES: 'property',
  };

  const sectionToComponentMapIds = {
    [sectionToComponentMap.COVER]: 'cover',
    [sectionToComponentMap.MARKET_MAP]: 'marketmap',
    [sectionToComponentMap.PROPERTY_COMPARISON]: 'comparison',
    [sectionToComponentMap.PROPERTIES]: 'properties',
    [sectionToComponentMap.TOUR_SCHEDULE]: 'schedule',
  };

  const sectionToComponentMapName = {
    [sectionToComponentMap.COVER]: 'Overview',
    [sectionToComponentMap.MARKET_MAP]: 'Market Map',
    [sectionToComponentMap.PROPERTY_COMPARISON]: 'Property Comparison',
    [sectionToComponentMap.PROPERTIES]: 'Properties',
    [sectionToComponentMap.TOUR_SCHEDULE]: 'Tour Schedule',
  };

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const sectionIdNav = sectionToComponentMapIds[shownSections.slice(0, 1)[0].name];
  const sectionDisplatName = sectionToComponentMapName[splitLocation[2]];
  const sectionId = sectionToComponentMapIds[splitLocation[2]];
  const { getIconData } = mapUtil.default;
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileopen);
  };
  const displayPropertyName = () => {
    const property = shownProperty.find((item: Property) => item.id === splitLocation[3]);
    return property ? property.name : 'Properties';
  };
  const isShownProperty = () => {
    let propertiesMenu = shownProperty.filter((property: Property) => (!property.isCurrentLocation
      && ((shortlisted && property.isClientShortlist)
        || !shortlisted)));

    propertiesMenu = propertiesMenu.length > 0 ? propertiesMenu : shownProperty;

    return propertiesMenu.map(
      (property: Property, index: number) => (
        <MenuItem
          onClick={() => {
            goToProperty(property);
            handleClose();
            handleMobileClose();
          }}
          className={classes.menuItem}
          key={property.id}
        >
          <span
            className={classes.serialNumber}
            style={{ backgroundColor: getIconData(property).iconColor }}
          >
            {index + 1}
          </span>
          <span className={classes.propertyName}>
            {property.name}
          </span>
        </MenuItem>
      ));
  };

  const drawer = (
    <List>
      <LinkTabItem shownSections={shownSections.slice(0, propertiesSectionIdx)} surveyId={surveyId} />
      {propertiesPresent && (
        <>
          <IconButton
            onClick={handleDrawerClick}
            className={[drawerArrow ? classes.dropDownUp : classes.dropDownDown, classes.propertiesDropdown].join(' ')}
          >
            Properties{' '}
            <ExpandMoreIcon
              className={
                drawerArrow && drawerAnchorEl ? classes.dropDownIconUp : ''
              }
            />
          </IconButton>
          {shownProperty && shownProperty.length > 0 && (
            <Menu
              id="basic-menu"
              anchorEl={drawerAnchorEl}
              getContentAnchorEl={null}
              open={drawerOpen}
              onClose={handleDrawerClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              className={[classes.drawerMenu, classes.rightAlign].join(' ')}
            >
              {isShownProperty()}
            </Menu>
          )}
        </>
      )}

      <LinkTabItem shownSections={shownSections.slice(propertiesSectionIdx + 1)} surveyId={surveyId} />

      <MUILink
        href="https://www.cbre.com/about-us/disclaimer-terms-of-use"
        target="_blank"
        className={classes.mobileDisclaimer}
      >
        Disclaimer
      </MUILink>
    </List>
  );

  const container = window ? () => window.document.body : undefined;

  return (
    <>
      {typeof splitLocation[2] === 'undefined' && (
        <RedirectLink href={`/${surveyId}/${sectionIdNav}`} ></RedirectLink>
      )}
      {typeof splitLocation[2] === 'undefined' && sectionIdNav === SURVEY_SECTIONS_IDS.PROPERTIES && (
        <RedirectLink href={`/${surveyId}/property/${shownProperty[0].id}`} ></RedirectLink>
      )}
      {isDesktop &&
        <LinkTabItem shownSections={shownSections.slice(0, propertiesSectionIdx)} surveyId={surveyId} />}
      {propertiesPresent && isDesktop && (
        <>
          <IconButton
            onClick={handleClick}
            className={arrow ? classes.dropDownUp : classes.dropDownDown}
          >
            Properties{' '}
            <ExpandMoreIcon
              className={
                arrow ? classes.dropDownIconUp : ''
              }
            />
          </IconButton>
          {shownProperty?.length > 0 && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              className={classes.menu}
            >
              {isShownProperty()}
            </Menu>
          )}
        </>
      )}

      <MUILink
        href="https://www.cbre.com/about-us/disclaimer-terms-of-use"
        target="_blank"
        className={classes.disclaimer}
      >
        Disclaimer
      </MUILink>
      {isDesktop
        && <LinkTabItem shownSections={shownSections.slice(propertiesSectionIdx + 1)} surveyId={surveyId} />}
      {splitLocation[2] === 'property' && !isDesktop && propertiesPresent ? (
        <>
          <IconButton
            onClick={handleMobileClick}
            className={[mobileArrow ? classes.dropDownUp : classes.dropDownDown, classes.propertiesDropdown, classes.mobilePropertiesDropdown].join(' ')}
          >
            {displayPropertyName()}{' '}

          </IconButton>
          <ExpandMoreIcon
            className={
              mobileArrow ? classes.dropDownIconUp : ''
            }
            onClick={handleMobileClick}

          />
          {shownProperty?.length > 0 && (
            <Menu
              id="basic-menu"
              anchorEl={mobileAnchorEl}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={mobileOpen}
              onClose={handleMobileClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              className={classes.menu}
            >
              {isShownProperty()}
            </Menu>
          )}
        </>
      ) : <Link
        to={`/${surveyId}/${sectionId}`}
        className={[classes.active, classes.mobileView, classes.desktopSpace].join(' ')}
      >{sectionDisplatName}</Link>}
      <IconButton className={[classes.mobileView].join(' ')} color="inherit" onClick={handleDrawerToggle}>
        <MenuIcon />
      </IconButton>
      <Box component="nav" >
        <Drawer
          anchor="right" classes={{ paper: classes.drawerSection }} container={container} variant="temporary" open={mobileopen}
          onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }}>
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

SectionTabs.displayName = 'SectionTabs';
export default SectionTabs;
