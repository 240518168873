import React from 'react';
import { SvgIcon } from '@material-ui/core';

type Props = {
  height: number,
  width: number,
};

const MapSettingMenuIcon: React.FC<Props> = (props) => (
  <SvgIcon {...props}>
    <path
      fill="rgba(38, 38, 38, 0.6)"
      fillRule="evenodd"
      d="M1 4.443a1 1 0 0 1 .351.064L5 5.875V19L.649 17.368A1 1 0 0 1 0 16.432V5.443a1 1 0 0 1 1-1zm8.038.292A7.473 7.473 0 0 0 11 10.6L11 17.125 6 19V5.875l3.038-1.14zm2.963 6.766a7.467 7.467 0 0 0 5 1.483L17 17.557a1 1 0 0 1-1.351.936L12 17.125v-5.624zM15.69.06c.537-.08 1.081-.08 1.617 0l.193 1.439c.394.099.772.255 1.12.464l1.154-.881a5.5 5.5 0 0 1 1.143 1.143l-.88 1.154c.208.348.364.726.463 1.12l1.44.193c.079.536.079 1.08 0 1.616l-1.44.193a4.126 4.126 0 0 1-.464 1.12l.881 1.154a5.5 5.5 0 0 1-1.143 1.143l-1.154-.88a4.126 4.126 0 0 1-1.12.463l-.193 1.44c-.536.079-1.08.079-1.616 0l-.193-1.44a4.126 4.126 0 0 1-1.12-.464l-1.154.881a5.5 5.5 0 0 1-1.143-1.143l.88-1.154a4.126 4.126 0 0 1-.463-1.12l-1.44-.193a5.501 5.501 0 0 1 0-1.616l1.44-.193c.099-.394.255-.772.464-1.12l-.881-1.154a5.5 5.5 0 0 1 1.143-1.143l1.154.88a4.126 4.126 0 0 1 1.12-.463zm.809 3.55a1.89 1.89 0 1 0 .002 3.781 1.89 1.89 0 0 0-.002-3.78z"
    />
  </SvgIcon>
);

MapSettingMenuIcon.displayName = 'MapSettingMenuIcon';
export default MapSettingMenuIcon;
