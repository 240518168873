import React from 'react';
import { makeStyles, SvgIcon, Theme, createStyles } from '@material-ui/core';

type Props = {
  stroke: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: 'white',
      fontSize: '3rem',
      '@media (max-width: 767px)': {
        fontSize: '2rem',
      },
    },
    header: {
      color: theme.palette.grey['200'],
    },
  }));

const RightArrowIcon: React.FC<Props> = ({ stroke }) => {
  const classes = useStyles();
  return (
   <SvgIcon
    className={classes.root}
    width="50" height="50" viewBox="0 0 50 50"
    fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
      className={classes.header}
      cx="25" cy="25" r="24"
      transform="rotate(180 25 25)" stroke={stroke} strokeWidth="1.31081"/>
<path d="M14.6191 24.9996H35.3759M35.3759 24.9996L28.548 17.2158M35.3759 24.9996L28.548 32.7834" stroke={stroke} strokeWidth="1.31081"/>
  </SvgIcon>
  );
};
RightArrowIcon.displayName = 'LeftArrowIcon';
export default RightArrowIcon;
