import { Dispatch, SetStateAction, useState } from 'react';
import {
  Survey,
  SurveyPatch,
  PropertyFieldRulePatch,
} from '../../types';
import {
  isChangingMapSetting,
} from '../../utilities';
import * as surveyService from '../survey-service';
import updatePropertyFieldRules from '../survey-propertyField-rule-service';

export type UseSurveyAPI = {
  setSurvey: Dispatch<SetStateAction<Survey>>;
  survey: Survey;
  updateSurvey: (patch: SurveyPatch) => void;
   updatePropertyFieldRule: (patch: PropertyFieldRulePatch) => void;
};

// eslint-disable-next-line no-console
const logError = (message?: string) => (error: Error) =>
  // eslint-disable-next-line no-console
  console.error(message || 'Error', error);

export default function useSurveyAPI(): UseSurveyAPI {
  const [survey, setSurvey] = useState<Survey>({} as Survey);

  const updateSurvey = (patchParam: SurveyPatch) => {
    let patch = {
      ...patchParam,
    };
    if (isChangingMapSetting(patchParam)) {
      patch = {
        ...patch,
        marketMapBamData: '{"mapReset":true}',
      };
    }
    const revert = { ...survey };
    setSurvey({
      ...survey,
      ...patch,
      updatedOn: new Date(Date.now()).toISOString(),
    } as Survey);
    surveyService
      .updateSurvey(patch)
      .then((res: any) => {
        if (res.updatedOn >= survey.updatedOn) {
          /**
           * When server is responding we will have to reconcile nested objects
           * contacts, customFields, sections, properties and property.customFields, property.availabilities
           */
          setSurvey(res);
        }
      })
      .catch((error: any) => {
        logError('Failed to update Survey')(error);
        setSurvey(revert);
      });
  };
  const setCurrentSurvey = (res: any) => (
    setSurvey((current: Survey) => {
      if (current) {
        return {
          ...current,
          surveyPropertyFieldRule: { ...res },
        };
      }
      return current;
    })
  );

  const catchCurrentSurvey = (currentPropertyFieldRules: any) => (
    setSurvey((current: Survey) => {
      if (current) {
        return {
          ...current,
          surveyPropertyFieldRule: { ...currentPropertyFieldRules },
        };
      }
      return current;
    })
  );

  const updatePropertyFieldRule = (
    propertyFieldRule: PropertyFieldRulePatch,
  ) => {
    const currentPropertyFieldRules = survey.surveyPropertyFieldRule;
    updatePropertyFieldRules(survey.id, propertyFieldRule)
      .then((res) => {
        setCurrentSurvey(res);
      })
      .catch((error) => {
        catchCurrentSurvey(currentPropertyFieldRules);
        logError('Failed to update property field rule')(error);
      });
  };

  return {
    setSurvey,
    survey,
    updateSurvey,
    updatePropertyFieldRule,
  };
}
