import {
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SurveySection } from '../../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      color: theme.palette.primary.light,
      paddingLeft: 32,
      display: 'flex',
      font: 'Calibre-R',
      fontSize: 14,
      textUnderlinePosition: 'under',
    },
    inActive: {
      textDecoration: 'none',
      paddingLeft: 32,
      display: 'flex',
      font: 'Calibre-R',
      color: theme.palette.primary.contrastText,
      fontSize: 14,
      '&:hover': {
        color: theme.palette.primary.light,
      },
    },
    mobileView: {
      display: 'none',
      '@media (max-width: 767px)': {
        display: 'block',
      },
    },
    mobileSpace: {
      '@media (max-width: 767px)': {
        marginTop: 12,
      },
    },
    desktopSpace: {
      '@media (max-width: 768px)': {
        marginLeft: 5,
        padding: 5,
      },
      '@media (max-width: 767px)': {
        marginLeft: 20,
      },
    },
    desktopView: {
      display: 'block',
      '@media (min-width: 767px)': {
        display: 'none',
      },
    },
  }),
);

type Props = {
  surveyId: string,
  shownSections: any
};

const LinkTabItem: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { surveyId, shownSections } = props;

  // assigning location variable for active/inactive link
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  const SURVEY_SECTIONS = {
    COVER: 'cover',
    MARKET_MAP: 'market map',
    PROPERTY_COMPARISON: 'property comparison',
    TOUR_SCHEDULE: 'tour schedule',
  };

  const sectionToComponentMap = {
    [SURVEY_SECTIONS.COVER]: 'Overview',
    [SURVEY_SECTIONS.MARKET_MAP]: 'Market Map',
    [SURVEY_SECTIONS.PROPERTY_COMPARISON]: 'Property Comparison',
    [SURVEY_SECTIONS.TOUR_SCHEDULE]: 'Tour Schedule',
  };

  const SURVEY_SECTIONS_IDS = {
    COVER: 'cover',
    MARKET_MAP: 'market map',
    PROPERTY_COMPARISON: 'property comparison',
    TOUR_SCHEDULE: 'tour schedule',
  };

  const sectionToComponentMapIds = {
    [SURVEY_SECTIONS_IDS.COVER]: 'cover',
    [SURVEY_SECTIONS_IDS.MARKET_MAP]: 'marketmap',
    [SURVEY_SECTIONS_IDS.PROPERTY_COMPARISON]: 'comparison',
    [SURVEY_SECTIONS_IDS.TOUR_SCHEDULE]: 'tour',
  };

  return (
    <>
      {shownSections.map((item: SurveySection) => {
        const sectionDisplatName = sectionToComponentMap[item.name];
        const sectionId = sectionToComponentMapIds[item.name];
        return (
          <Link
to={`/${surveyId}/${sectionId}`}
            className={[splitLocation[3] === sectionId ? classes.active : classes.inActive, classes.mobileSpace, classes.desktopSpace].join(' ')}
            key={item.surveySectionId}
          >{sectionDisplatName}</Link>
        );
      })}
    </>
  );
};

LinkTabItem.displayName = 'LinkTabItem';
export default LinkTabItem;
