import React, { useEffect, useState, useContext, useReducer } from 'react';
import {
  createStyles,
  makeStyles,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Theme,
  Link,
  Switch,
  FormGroup,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core';
import { Typography, externalTheme } from '@cbreone/core-ui';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Property } from '../../types';
import PropertyFeatureImageForComparision from '../../components/PropertyFeatureImage/PropertyFeatureImageForComparision';
import { getFullAddressStr, surveyShortlistedOverride, addElementIdToMUINestedElement } from '../../utilities';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import { linkAttributes } from '../../data/constants';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import { getPropertyValueForDisplay2 } from '../../utilities/property';

const defaultZIndex = 100;

const useStyles = makeStyles((theme: Theme) => createStyles({
  // new from BH
  tableCellRoot: {
    whiteSpace: 'pre-line',
    wordWrap: 'break-word',
  },
  // from CBREOne
  table: {
    minWidth: 650,
  },
  sticky: {
    position: 'sticky',
    left: 0,
    background: 'rgba(245, 247, 247, 1)',
    width: '200px',
    zIndex: defaultZIndex,
    backgroundColor: theme.palette.grey['50'],
    verticalAlign: 'top',
    '@media (max-width: 767px)': {
      width: '104px',
    },
  },
  stickyEven: {
    position: 'sticky',
    left: 0,
    background: 'rgba(230, 234, 234, 1)',
    width: '200px',
    zIndex: defaultZIndex,
    backgroundColor: theme.palette.grey['200'],
    verticalAlign: 'top',
    '@media (max-width: 767px)': {
      width: '104px',
    },
  },
  columnWidth: {
    width: '235px',
    color: theme.palette.text.primary,
    '@media (max-width: 767px)': {
      width: '114px',
    },
  },
  imageArea: {
    verticalAlign: 'top',
    backgroundColor: theme.palette.primary.contrastText,
  },
  comparisonCell: {
    height: '37px',
    borderBottom: '0px',
    padding: '5px',
    fontSize: '14px',
    wordWrap: 'break-word',
    verticalAlign: 'top',
  },
  oddRow: {
    background: theme.palette.primary.contrastText,
  },
  evenRow: {
    background: theme.palette.grey['100'],
  },
  tableContainer: {
    width: '100%',
    height: 'calc(100vh - 48px)',
    overflowY: 'auto',
  },
  leftColumnBg: {
    backgroundColor: theme.palette.grey['100'],
  },
  link: {
    wordWrap: 'break-word',
  },
  shortListRow: {
    '@media (min-width: 767px)': {
      alignItems: 'center',
    },
    '@media (max-width: 767px)': {
      padding: '8px',
    },
  },
  shortListToggle: {
    color: externalTheme.palette.primary.main,
    '@media (max-width: 767px)': {
      flexDirection: 'column-reverse',
      marginLeft: '0px !important',
    },
  },
  customWidth: {
    maxWidth: 200,
    backgroundColor: theme.palette.common.black,
    fontFamily: 'Calibre-R',
    fontSize: '14px',
    lineHeight: '21px',
    alignItems: 'center',
  },
  tipArrow: {
    color: theme.palette.common.black,
  },
  chip: {
    width: '10.26px',
    height: '7.82px',
    backgroundColor: externalTheme.palette.primary.main,
    color: externalTheme.palette.primary.contrastText,
  },
  icon: {
    color: externalTheme.palette.primary.main,
    width: '16.67px',
    height: '16.67px',
  },
}));

type Props = {};

const InteractiveSurveyComparison: React.FC<Props> = () => {
  const classes = useStyles();
  const { survey } = useContext(InteractiveSurveyContext);
  const [shortlisted, setShortlisted] = useState((localStorage.getItem('shortlisted') === 'true'));
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const tooltipTitle = 'Once you begin shortlisting you can filter your view to show only shortlisted properties. You can review these with your brokerage team.';
  const [property, setProperty] = useState<any>({
    properties: [],
    pageMode: '',
    firstPage: true,
    activeAddressKeys: [],
    activePropertyFieldRuleList: [],
  });
  const muiSwitchRootElement = document.getElementsByClassName('MuiSwitch-root')[0];
  addElementIdToMUINestedElement(muiSwitchRootElement, 'SC-Shortlist-Filter');

  const resetProperties = () => {
    if (Object.keys(survey).length > 0) {
      let properties: any[] = [];
      if (survey?.properties) {
        properties = survey.showCurrentLocation ? (survey.properties
          .filter((item: any) => surveyShortlistedOverride(shortlisted, item))) :
          (survey.properties
            .filter((item: any) => surveyShortlistedOverride(shortlisted, item) &&
              !item.isCurrentLocation));
      }
      const addressKeys = ['address1', 'city', 'state', 'postalCode'];
      const activeAddressKeys = survey?.surveyPropertyFieldRule?.filter((rule: Record<string, any>) =>
        addressKeys.includes(rule.fieldKey),
      )
        .map((rule: Record<string, any>) => {
          if (rule.status !== 'ACTIVE') {
            return '';
          }
          return rule.fieldKey;
        });
      const fieldRuleList = survey?.surveyPropertyFieldRule?.filter((rule: Record<string, any>) => rule.fieldKey !== 'name' && !addressKeys.includes(rule.fieldKey) && rule.status === 'ACTIVE');
      setProperty({
        ...properties,
        activeAddressKeys,
        activePropertyFieldRuleList: fieldRuleList,
        properties,
      });
      if (properties.length === 0) toggleShortlisted();
    }
  };

  useEffect(() => {
    resetProperties();
  }, [survey, reducerValue]);

  useEffect(() => {
    window.localStorage.setItem('shortlisted', JSON.stringify(shortlisted));
  }, [shortlisted]);

  const {
    properties,
    activeAddressKeys,
    activePropertyFieldRuleList,
  } = property;

  const toggleShortlisted = () => {
    setShortlisted(!shortlisted);
    forceUpdate();
  };

  const shortlistedDisabled = () => survey.properties.filter((item) =>
    item.isClientShortlist).length === 0;

  const displayImageRow = () => (
    <>
      <TableHead>
        <TableRow style={{ background: 'red', height: 310 }}>
          <TableCell className={classes.sticky} style={{ zIndex: defaultZIndex + 1 }}>
            <FormGroup row className={classes.shortListRow}>
              <Tooltip
                open={tooltipIsOpen}
                onOpen={() => setTooltipIsOpen(true)}
                onClose={() => setTooltipIsOpen(false)}
                title={tooltipTitle}
                arrow
                placement="top-start"
                classes={{ tooltip: classes.customWidth, arrow: classes.tipArrow }}>
                <InfoOutlinedIcon
                  className={classes.icon}
                />
              </Tooltip>
              <FormControlLabel
                className={classes.shortListToggle}
                control={
                  <div>
                    <Switch
                      disabled={shortlistedDisabled()}
                      checked={shortlisted}
                      onChange={toggleShortlisted}
                      color="primary"
                    />
                  </div>
                }
                label='Shortlisted'
                labelPlacement="start"
              />
            </FormGroup>
          </TableCell>
          {properties.filter((item: any) => (surveyShortlistedOverride(shortlisted, item))).map(
            (propertyProp: any, index: number) => {
              const propValues: [string, string, string, string] = [
                propertyProp[activeAddressKeys[0] as keyof Property] as string,
                propertyProp[activeAddressKeys[1] as keyof Property] as string,
                propertyProp[activeAddressKeys[2] as keyof Property] as string,
                propertyProp[activeAddressKeys[3] as keyof Property] as string,
              ];
              const fullAddressStr = getFullAddressStr(...propValues);
              return (
                <TableCell key={propertyProp.id} className={`${classes.columnWidth} ${classes.imageArea}`}>
                  <PropertyFeatureImageForComparision
                    propertyId={propertyProp.id}
                    survey={survey}
                    size="thumbnail"
                    fullAddressStr={fullAddressStr}
                    name={propertyProp.name || '-'}
                    resetProperties={resetProperties}
                    shortlistToggle={shortlisted}
                  />
                </TableCell>
              );
            })}
        </TableRow>
      </TableHead>
    </>
  );

  const tableCellStyle = (index: number) => {
    const cellStyle = index % 2 ? [classes.sticky, classes.leftColumnBg].join(' ') : classes.stickyEven;
    return `${cellStyle} ${classes.comparisonCell}`;
  };

  const displayPropertyFieldRuleRow = (
    row: BHPropertyFieldRule,
    index: number,
  ) => (
    <>
      {
        <TableRow
          key={row.fieldKey}
          className={`${index % 2 ? classes.oddRow : classes.evenRow}`}
        >
          <TableCell
            component="th"
            className={tableCellStyle(index)}
            scope="row"
          >
            <Typography variant="body2">{row.fieldLabel}</Typography>
          </TableCell>
          {properties.filter((item: any) => surveyShortlistedOverride(shortlisted, item)).map(
            (propertyProp: any) => {
              const attrKey = row.fieldKey;
              const data = propertyProp as Property & { [x: string]: any; };
              const { customFields } = data;
              let attrValue = '';
              let customField = null;
              if (customFields?.length > 0) {
                customField = customFields.find((field) => (
                  field.fieldKey === row.fieldKey));
              }
              if (customField) {
                attrValue = customField?.value || '';
              } else {
                attrValue = getPropertyValueForDisplay2(attrKey, data);
              }
              return (
                <TableCell className={`${classes.columnWidth} ${classes.comparisonCell}`} classes={{ root: classes.tableCellRoot }}>
                  {(linkAttributes.includes(row.fieldLabel) && !!attrValue) ?
                    <Link href={attrValue} className={classes.link}>{attrValue}</Link> :
                    <Typography variant="body2">{attrValue || '-'}</Typography>}
                </TableCell>
              );
            })}
        </TableRow>
      }
    </>
  );

  return (
    <TableContainer className={classes.tableContainer}>
      <Table
        stickyHeader
        className={classes.table}
        style={{ tableLayout: 'fixed' }}
      >
        {survey?.properties != null && displayImageRow()}
        {
          // Display the rest of the property attribute rows
          activePropertyFieldRuleList.map(
            (row: BHPropertyFieldRule, index: number) =>
              displayPropertyFieldRuleRow(row, index),
          )
        }
      </Table>
    </TableContainer>
  );
};

InteractiveSurveyComparison.displayName = 'InteractiveSurveyComparison';
export default InteractiveSurveyComparison;
