import { mediaServiceBaseURL, propertyServiceBaseURL, surveyServiceBaseURL } from '../../config';
import { Property, Survey } from '../../types';
import { InteractiveLogin } from '../../types/interactive/login';
import customAxios from '../../utilities/axios/customAxios';

import onSuccess from '../onSuccess';

export const login = (loginData: InteractiveLogin) =>
  customAxios
    .post<InteractiveLogin>(
      `${surveyServiceBaseURL}interactive/login`,
      loginData,
    )
    .then(onSuccess);

export const getSurvey = (id: string) =>
  customAxios
    .get<Survey>(`${surveyServiceBaseURL}survey/${id}`, {
      headers: { surveyId: id },
    })
    .then(onSuccess)
    .catch((error) => {
      window.location.href = `/${id}/interactive/login`;
    });

export const getFeatureImageForProperty = (
  surveyId: string,
  propertyId: string,
  mediaType: string,
) =>
  customAxios
    .get<any>(
      `${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}/featureImage`, {
        headers: { surveyId },
      },
    )
    .then(onSuccess)
    .catch();

export const getClientLogoForSurvey = (
  surveyId: string,
) =>
  customAxios
    .get<any>(
      `${mediaServiceBaseURL}${surveyId}/CLIENTLOGO/active`, {
        headers: { surveyId },
      },
    )
    .then(onSuccess);

export const getActiveMediaFilesForProperty = (
  surveyId: string,
  propertyId: string,
  mediaType: string,
) =>
  customAxios
    .get<any>(
      `${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}/active`, {
        headers: { surveyId },
      },
    )
    .then(onSuccess);

export const updateProperty = (property: any, surveyId: string) =>
  customAxios
    .patch<Property>(`${propertyServiceBaseURL}property`, {
      id: property.id,
      isClientShortlist: property.isClientShortlist,
    }, {
      headers: { surveyId },
    })
    .then(onSuccess)
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error', error);
      window.location.href = `/${surveyId}/interactive/login`;
    });
