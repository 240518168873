import React from 'react';
import {
  createStyles, makeStyles, Table, TableBody, TableRow, TableCell, Theme, Typography, Link,
} from '@material-ui/core';
import { Property, Survey } from '../../types';
import { linkAttributes } from '../../data/constants';
import { BHPropertyFieldRule } from '../../types/bh-property-field-rule';
import { getPropertyValueForDisplay } from '../../utilities/property';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    wordWrap: 'break-word',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey['100'],
    },
    '&:nth-of-type(even)': {
      background: theme.palette.common.white,
    },
    verticalAlign: 'top',
  },
  noBottomBorder: {
    borderBottom: 'none',
    color: theme.palette.text.primary,
    width: '50px',
    maxWidth: '50px',
  },
  link: {
    wordWrap: 'break-word',
    fontSize: '16px',
  },
}));

const InteractivePropertyDetail: React.FC<Props> = ({
  survey,
  property,
}) => {
  const classes = useStyles();
  const ignoreKeys = ['address1', 'city', 'state', 'postalCode', 'comments', 'amenities', 'propertyProposedSpace', 'proposedSpace'];
  const propertyId = survey?.properties?.filter(
    (propertyField) => propertyField.id === property.id);
  const activePropertyFieldRuleList = survey?.surveyPropertyFieldRule?.filter((rule:any) => rule.fieldKey !== 'name' && !ignoreKeys.includes(rule.fieldKey) && rule.status === 'ACTIVE');

  const displayPropertyFieldRuleRow = (row:BHPropertyFieldRule, index:number) => (

      <TableRow key={row.fieldKey} className={classes.root}>
      {
        <TableCell component="th" className={classes.noBottomBorder} key={index}>
          <Typography>
            {row.fieldLabel}
          </Typography>
        </TableCell>
      }
      {
        propertyId.map((x) => {
          const attrKey = row.fieldKey;
          const data = property as Property & { [x:string]:any };
          const { customFields } = data;
          let attrValue = '';
          let customField = null;
          if (Array.isArray(customFields) && customFields.length > 0) {
            customField = customFields.find((field) => (
              field.fieldKey === row.fieldKey));
          }
          if (customField) {
            attrValue = customField?.value || '';
          } else {
            attrValue = getPropertyValueForDisplay(attrKey, data);
          }
          return (
            <TableCell
              className={classes.noBottomBorder}
              component="th"
              scope="row"
              key={attrKey}
            >
              {(linkAttributes.includes(row.fieldLabel) && !!attrValue) ?
                <Link href={attrValue} className={classes.link}>{attrValue}</Link> :
                <Typography>
                  {attrValue || '-'}
                </Typography>}
            </TableCell>
          );
        })
      }
      </TableRow>
  );

  return (
    <div>
      <Table size="small">
        <TableBody>
          {
            activePropertyFieldRuleList?.map((row:any, index:any) => (
              displayPropertyFieldRuleRow(row, index)
            ))
          }
        </TableBody>
      </Table>
    </div>
  );
};
export type Props = {
  survey: Survey;
  property: Property;
};

export default InteractivePropertyDetail;
