/* eslint-disable max-len */

import { isNumber } from 'lodash';
import { MediaFile, Property, PropertyPatch, SurveyPatch } from '../types';
/**
 * get chunks of array list.
 * @param originArray
 * @param size
 * @param chunkIndex
 */
export const getArrayChunks = (originArray: any, size: number) => Array.from(
  new Array(Math.ceil(originArray.length / size)),
  (_, i) => originArray.slice(i * size, i * size + size),
);

/**
 * return the formatted date.
 * @param date
 * @returns
 */
export const getFormattedDate = (date: Date) => {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];
  const year = date.getFullYear();
  const day = date.getUTCDate();
  return `${monthName} ${day},  ${year}`;
};

export const checkValueIsUnavailable = (obj: any) =>
  obj === null || obj === undefined || obj === '';

// eslint-disable-next-line arrow-body-style
export const checkCoordinateIsvalid = (obj: any) => {
  return obj === null || obj === undefined || obj === '' || !isNumber(Number(obj));
};

/**
 * Returns full address formatted
 * @param street
 * @param city
 * @param state
 * @param zip
 * @returns
 */
export const getFullAddressStr = (
  street: string,
  city: string,
  state: string,
  zip: string,
) => {
  if (!street && !city && !state && !zip) return '';
  const line1 = street;
  let line2 = '';
  if (city) line2 += city;
  if (city && state) line2 += ', ';
  if (city && !state && zip) line2 += ' ';
  if (state) line2 += state;
  if ((city && zip) || (state && zip)) line2 += ' ';
  if (zip) line2 += zip;

  const fullAddressValidParts = [line1, line2].filter((p) => p);
  return fullAddressValidParts.join('\n');
};

/**
 * check if the object is empty after converted from string
 * @param inputStr
 * @returns
 */
export const isEmptyObjectFromStr = (inputStr: string) =>
  Object.keys(JSON.parse(inputStr)).length === 0;

export const mediaFileToGalleryFile = (media: MediaFile) => ({
  id: media.mediaId,
  url: media.origin !== 'SiteIQ' ? `${media.cloudfrontUrl}` : media.cloudfrontUrl,
  previewUrl: media.pdfImageUrl,
  thumbnailUrl: media.thumbnailUrl,
  displayText: media.displayName,
  active: !media.isHidden,
  primary: false,
  order: media.order,
  deletable: (!(media.origin && media.origin.toLowerCase() === 'siteiq')),
  origin: media.origin,
  modifiedFromMediaId: media.modifiedFromMediaId,

});

export const isChangingMapSetting = (
  patch: SurveyPatch | PropertyPatch,
) => {
  const attributes = [
    'isIncludeLegend',
    'isIncludeHeader',
    'pdfPaperSize',
  ];
  if (Object.keys(patch).some((attr) => attributes.includes(attr))) {
    return true;
  }
  return false;
};

export const flattenMediaFilesWithModifiedFiles = (flyers: any[]) => {
  const files = flyers;
  files.forEach((item, i) => {
    const index = files.findIndex((res) => item.mediaId === res.modifiedFromMediaId);
    if (index > -1) {
      files[i] = files[index];
      files.splice(index, 1);
    }
  });
  return files;
};
export const surveyShortlistedOverride = (shortlisted: boolean, property: Property) => (
  (shortlisted && property.isClientShortlist && !property.isHidden)
    || (!property.isHidden && !shortlisted)
);

export const addElementIdToMUINestedElement = (element: any, id: string) => element?.setAttribute('id', id);
