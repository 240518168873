export const PDFPaperSize = {
  Landscape: 'Landscape',
  Portrait: 'Portrait',
};

// eslint-disable-next-line no-shadow
export enum MediaFileType {
  images = 'IMAGES',
  fliers = 'FLIERS',
  floorplans = 'FLOORPLANS'
}

export const linkAttributes = ['Website', 'Virtual Tour'];

export const excludedTableFields = ['Address', 'Amenities', 'City', 'Comments', 'Postal Code', 'State Province'];
