import React from 'react';
import { Redirect } from 'react-router-dom';

type Props = {
    href: string
};

const RedirectLink: React.FC<Props> = (props) => {
  const { href } = props;

  return (
     <Redirect to={href} />
  );
};

RedirectLink.displayName = 'RedirectLink';
export default RedirectLink;
