import useSurveyAPI, { UseSurveyAPI } from './useSurveyAPI';
import usePropertiesAPI, { UsePropertyAPI } from './usePropertiesAPI';

export type UseInteractiveAPI = Omit<UseSurveyAPI & UsePropertyAPI, 'setSurvey'>;

// eslint-disable-next-line max-len
export default function useInteractiveAPI(surveyId: string): UseInteractiveAPI {
  const {
    setSurvey,
    survey,
    updateSurvey,
    updatePropertyFieldRule,
  } = useSurveyAPI();

  const {
    updateInteractiveProperty,
    // eslint-disable-next-line max-len
  } = usePropertiesAPI({ survey, setSurvey, updatePropertyFieldRule });

  return {
    survey,
    updateInteractiveProperty,
    updateSurvey,
    updatePropertyFieldRule,
  };
}
