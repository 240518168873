/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/prefer-default-export
import { defineCustomElements } from '@cbre-internal/mapping-component/loader';
import InteractiveApp from './apps/InteractiveApp';
import PDFViewer from './apps/PDFViewer';

export {
  InteractiveApp,
  PDFViewer,
};
defineCustomElements(window);
