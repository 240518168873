import { propertyServiceBaseURL, surveyServiceBaseURL } from '../config';
import { Property, Survey, SurveyPatch } from '../types';
import { BHPropertyRules } from '../types/bh-property-field-rule';
import customAxios from '../utilities/axios/customAxios';
import onSuccess from './onSuccess';
import { getProperties } from './property-service';
import { getCustomFields } from './survey-customField-service';

export const getSurvey = (id: string) => new Promise((resolve, reject) => {
  Promise.all([
    getSCSurvey(id),
    getProperties(id),
    getCustomFields(id),
    getPropertyFieldRule(id),
  ]).then((data) => {
    const survey = data[0] || {};
    const properties = data[1] || [];
    const customFields = data[2] || [];
    const propertyFieldRule = data[3] || [];
    resolve({
      ...survey,
      customFields,
      surveyPropertyFieldRule: propertyFieldRule.propertyAttributes,
      surveyPropertyAvailabilityRule: propertyFieldRule.availabilityAttributes,
      properties: properties.map((p: Property) => ({
        ...p,
        latitude: Number.isNaN(Number(p.latitude)) ? null : Number(p.latitude),
        longitude: Number.isNaN(Number(p.longitude)) ? null : Number(p.longitude),
        isHidden: p.status === 'INACTIVE',
      })),
    },
    );
  }).catch(() => {
    window.location.href = `/${id}/login`;
    reject();
  });
});

export const getSCSurvey = (id: string) => customAxios.get<Survey>(`${surveyServiceBaseURL}survey/${id}`, {
  headers: { surveyId: id },
}).then(onSuccess);

export const getPropertyFieldRule = (surveyId: string) => customAxios.get<BHPropertyRules>(`${propertyServiceBaseURL}propertyfieldrule/externalId/${surveyId}/ownerType/SC_SURVEY`, {
  headers: { surveyId },
}).then(onSuccess);

export const updateSurvey = (survey: SurveyPatch) => customAxios.patch<Survey>(`${surveyServiceBaseURL}survey`, survey, {
  headers: { surveyId: survey.id },
}).then(onSuccess);
