import React from 'react';
import {
  Theme,
  createStyles,
  makeStyles,
  Button,
} from '@material-ui/core';
import MapSettingCenterIcon from '../Icons/MapSettingCenterIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 1,
      right: 11,
      top: 108,
    },
    settingMenu: {
      background: 'white',
      boxShadow: '0 0 0 2px rgb(0 0 0 / 10%)',
      borderRadius: 4,
      height: 30,
      width: 30,
      padding: 4,
      cursor: 'pointer',
      display: 'block',
      minWidth: 30,
      '&:hover': {
        background: '#f1f0f0',
      },
    },
    mapStyleClass: {
      width: 155,
      height: 240,
    },
    mapStyleTitle: {
      margin: '12px 16px 10px 16px',
    },
    mapStyleList: {
      height: 200,
      paddingLeft: '16px',
      overflowY: 'auto',
      '& label': {
        marginRight: 0,
        height: 40,
        '& span': {
          fontSize: '0.875rem',
        },
      },
    },
  }));

const MapCenter: React.FC<Props> = (props) => {
  const { onRecenterChange } = props;
  const classes = useStyles();

  return (
    <section className={classes.root}>
      <Button className={classes.settingMenu} onClick={onRecenterChange}>
        <MapSettingCenterIcon height={42} width={42}></MapSettingCenterIcon>
      </Button>
    </section>
  );
};

type Props = {
  onRecenterChange: (e: any) => void;
};

MapCenter.displayName = 'MapCenter';
export default MapCenter;
