import React from 'react';
import InteractiveSurveyCover from '../pages/InteractiveSurveyCover';
import InteractiveSurveyLogin from '../pages/InteractiveSurveyLogin';
import InteractiveSurveyComparison from '../pages/InteractiveSurveyComparison';
import InteractiveSurveyMarketMap from '../pages/InteractiveSurveyMarketMap';
import InteractiveSurveyProperty from '../pages/InteractiveSurveyProperty';
import InteractiveSurveyTour from '../pages/InteractiveSurveyTour';
import InteractiveSurveyCallback from '../components/SFDC';

type Route = {
  path?: string;
  exact?: boolean;
  component: React.FC<any>;
};

export type RouteParams = {
  surveyId?: string;
};

export type PreviewRouteParams = {
  surveyId?: string;
  section?: string;
  propertyId?: string;
};

export type PropertyRouteParams = {
  surveyId: string;
  propertyId: string;
}

const routes: Route[] = [
  {
    path: '/:surveyId',
    exact: true,
    component: InteractiveSurveyCover,
  },
  {
    path: '/:surveyId/login',
    exact: true,
    component: InteractiveSurveyLogin,
  },
  {
    path: '/:surveyId/cover',
    exact: true,
    component: InteractiveSurveyCover,
  },
  {
    path: '/:surveyId/login/:passcode',
    exact: true,
    component: InteractiveSurveyCallback,
  },
  {
    path: '/:surveyId/comparison',
    exact: true,
    component: InteractiveSurveyComparison,
  },
  {
    path: '/:surveyId/marketmap',
    exact: true,
    component: InteractiveSurveyMarketMap,
  },
  {
    path: '/:surveyId/property/:propertyId',
    exact: true,
    component: InteractiveSurveyProperty,
  },
  {
    path: '/:surveyId/tour',
    exact: true,
    component: InteractiveSurveyTour,
  },
];

export default routes;
