import React from 'react';
import { SvgIcon } from '@material-ui/core';

type Props = {
  height: number;
  width: number;
};

const MapSettingCenterIcon: React.FC<Props> = (props) => (
  <SvgIcon
    {...props}
    viewBox="0 0 42 42"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_336_2349)" className="map-center-icon">
      <rect
x="5" y="4" width="42" height="42" rx="4"
fill="white" />
    </g>
    <circle cx="27.5" cy="13.5" r="2.5" fill="#262626" fillOpacity="0.6" />
    <circle cx="14.5" cy="13.5" r="2.5" fill="#262626" fillOpacity="0.6" />
    <circle cx="14.5" cy="26.5" r="2.5" fill="#262626" fillOpacity="0.6" />
    <circle cx="27.5" cy="26.5" r="2.5" fill="#262626" fillOpacity="0.6" />
  </SvgIcon>
);

MapSettingCenterIcon.displayName = 'MapSettingCenterIcon';
export default MapSettingCenterIcon;
