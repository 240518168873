import { axios } from '../internal-lib';
import onSuccess from './onSuccess';
import { mediaServiceBaseURL } from '../config';

export const getMediaFilesForProperty = (surveyId: string, propertyId:string, mediaType:string) => axios.get<any>(`${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}`).then(onSuccess);

export const getActiveMediaFilesForProperty = (surveyId: string, propertyId:string, mediaType:string) => axios.get<any>(`${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}/active`).then(onSuccess);

export const getFeatureImageForProperty = (surveyId: string, propertyId:string, mediaType:string) => axios.get<any>(`${mediaServiceBaseURL}${surveyId}/${propertyId}/${mediaType}/featureImage`).then(onSuccess);

export const exportMarketMap = (payload: any) => axios.post<any>(`${mediaServiceBaseURL}exportMap`, payload).then(onSuccess);
