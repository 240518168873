/* eslint-disable import/prefer-default-export */
import { propertyServiceBaseURL } from '../config';
import { Property } from '../types';
import onSuccess from './onSuccess';
import customAxios from '../utilities/axios/customAxios';

export const getProperties = (id?: string) =>
  customAxios
    .get<Property[]>(`${propertyServiceBaseURL}property/externalId/${id}/ownerType/SC_SURVEY`, {
      headers: { surveyId: id },
    })
    .then(onSuccess);
