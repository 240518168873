/* eslint-disable jsx-a11y/alt-text */
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { match } from 'react-router';
import { mediaRootURL } from '../../config';

type Props = {
	match: match;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pdfBox: {
      width: '100%',
      height: 'calc(100vh - 6px)',
    },
  }),
);

export type RouteParams = {
	encodedUrl?: string;
};

const PDFViewer: React.FC<Props> = ({ match: { params } }) => {
  const classes = useStyles();
  const { encodedUrl } = params as RouteParams;
  const pdfUrl = encodedUrl ? mediaRootURL + atob(encodedUrl) : null;
  return (
		<>
			{pdfUrl && (
				<object
					data={pdfUrl}
					type="application/pdf"
					className={classes.pdfBox}
				></object>
			)}
		</>
  );
};

PDFViewer.displayName = 'PDFViewer';
export default PDFViewer;
