import axios from '@cbreone/utilities/dist/axios';

const customAxios = axios.create();

const getValidToken = (token: any) => {
  if (!token || `${token}` === 'null' || `${token}` === 'undefined') {
    return null;
  }
  return token;
};

customAxios.interceptors.request.use((request) => {
  const token = localStorage.getItem('interactiveToken');
  const interactiveToken = getValidToken(token);
  return {
    ...request,
    headers: {
      ...request.headers,
      interactiveToken,
    },
  };
});

customAxios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response;
    // Go to login page if getting 401.
    if (status === 401) {
      // eslint-disable-next-line no-console
      console.log('redirect to the interactive login page');
    }
    return Promise.reject(error);
  },
);

export default customAxios;
