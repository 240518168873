import { externalTheme } from '@cbreone/core-ui/dist/themes';
import {
  CssBaseline,
  Theme,
  ThemeProvider,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { Suspense, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import InteractiveSurveyNavigation from '../../components/InteractiveSurveyNavigation';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import routes from '../../routes/interactive.routes';
import { Survey } from '../../types';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
  },
  contentBox: {
    marginTop: 48,
    width: '100%',
    height: 'calc(100vh - 49px)',
  },
}));

const InteractiveApp: React.FC<Props> = () => {
  const classes = useStyles();
  const [survey, setSurvey] = useState<Survey>({} as Survey);
  const value = useMemo(
    () => ({ survey, setSurvey }),
    [survey],
  );

  return (
    <ThemeProvider theme={externalTheme}>
      <BrowserRouter>
        <Suspense fallback={<div>Loading</div>}>
          <Switch>
            {routes.map(({ path, exact, component: Component }) => (
              <Route
                path={path}
                exact={exact}
                render={(props) => {
                  if (path === '/:surveyId/login') {
                    return <Component {...props} />;
                  }
                  if (path === '/:surveyId/login/:passcode') {
                    return <Component {...props} />;
                  }
                  return (
                      <div className={classes.root}>
                        <CssBaseline />
                        <InteractiveSurveyContext.Provider value={value}>
                          <InteractiveSurveyNavigation {...props}/>
                          <div className={classes.contentBox}>
                            <Component {...props} />
                          </div>
                        </InteractiveSurveyContext.Provider>
                      </div>
                  );
                }}
                key={path}
              />
            ))}
          </Switch>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export type Props = {};
InteractiveApp.displayName = 'InteractiveApp';
export default InteractiveApp;
