import { createContext } from 'react';
import { Property, Survey } from '../types';

const InteractiveSurveyContext = createContext({
  survey: {
    properties: [] as Property[],
  } as Survey,
  setSurvey: (survey:Survey) => {},
});
export default InteractiveSurveyContext;
