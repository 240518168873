/* eslint-disable jsx-a11y/alt-text */
import {
  Theme,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.MuiDialog-paper': {
      margin: 0,
    },
    '.MuiDialog-paperFullWidth': {
      width: '100%',
    },
    '.MuiDialog-paperWidthXl': {
      maxWidth: '100%',
    },
    '.MuiDialog-paperScrollPaper': {
      maxHeight: 'calc(100vh - 50px)',
      height: 'calc(100vh - 100px)',
      position: 'absolute',
      bottom: 50,
      top: 50,
    },
  },
  galleryContainer: {
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: '15px',
    overflow: 'hidden',
    [theme.breakpoints.only('md')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '10px',
      paddingRight: '10px',
    },
  },
  textContainer: {
    height: '100%',
  },
  activeImage: {
    maxWidth: '100%',
    width: 'auto',
    height: '70vh',
    [theme.breakpoints.only('md')]: {
      maxHeight: '100%',
      height: 'auto',
    },
    [theme.breakpoints.only('sm')]: {
      maxHeight: '100%',
      height: 'auto',
    },
  },
  activePdf: {
    width: '100%',
    height: '70vh',
  },
  imageContainer: {
    width: '100%',
    textAlign: 'center',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomContainer: {
    justifyContent: 'center',
    padding: '0px',
    overflow: 'hidden',
  },
  imageList: {
    height: '88px',
    width: '100%',
    cursor: 'pointer',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
  },
  borderedImage: {
    border: '2px solid #003F2D',
  },
  borderlessImage: {
    border: '2px solid transparent',
  },
  textEnd: { textAlign: 'end' },
  textCenter: { textAlign: 'center', color: '#435254', fontSize: 16, flex: 1, margin: 'auto' },
  mediaList: {
    overflowX: 'scroll',
    display: 'flex',
    paddingLeft: '100px',
    paddingRight: '100px',
    paddingBottom: 20,
  },
  mediaContainer: { width: '88px', marginRight: '15px', lineHeight: 0 },
  titleContainer: {
    display: 'flex',
  },
  overlay: {
    position: 'absolute',
    bottom: '-1px',
    left: '-1px',
    minHeight: '27%',
    backgroundColor: '#435354',
    opacity: '90%',
    color: '#fff',
    paddingLeft: '5px',
    width: 'calc(100% + 1.5px)',
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

interface PropertyImages {
  id?: number;
  url: string;
  mediaType: string;
  pdf?: string;
}

interface GalleryProps {
  medias: PropertyImages[];
  clickedMedia: number;
  open: boolean;
  setOpen: Function;
}

const Gallery: React.FC<GalleryProps> = ({ medias, clickedMedia, open, setOpen }) => {
  const classes = useStyles();
  const fullWidth = true;
  const maxWidth = 'xl';

  const [activeMedia, setActiveMedia] = React.useState<PropertyImages>({
    id: undefined,
    url: '',
    mediaType: '',
    pdf: '',
  });
  const [activeMediaIndex, setActiveMediaIndex] = React.useState<number>(0);

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (clickedMedia) {
      setOpen(true);
      const activeMediaIndexProp = medias.findIndex((item: PropertyImages) => item.id === clickedMedia);
      setActiveMedia(medias[activeMediaIndexProp]);
      setActiveMediaIndex(activeMediaIndexProp + 1);
    }
  }, [clickedMedia, medias]);
  const getOverlayText = (media: PropertyImages) => {
    let overlayText = '';
    if (media?.mediaType === 'FLIERS') overlayText = 'Flyer';
    if (media?.mediaType === 'FLOORPLANS') overlayText = 'Floorplan';
    return overlayText;
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        aria-labelledby='max-width-dialog-title'
      >
        <div className={classes.titleContainer}>

          <div className={classes.textCenter}>
            {activeMediaIndex}/{medias.length}
          </div>
          <div className={classes.textEnd}>
            <IconButton onClick={handleClose}>
              <CloseIcon fontSize='large' />
            </IconButton>
          </div>
        </div>
        <DialogContent
          className={classes.galleryContainer}
        >
          <DialogContentText className={classes.textContainer}>

            <Grid className={classes.imageContainer}>
              {(activeMedia.mediaType === 'FLIERS' || activeMedia?.pdf) ? (
                <object
                  data={activeMedia.pdf}
                  type='application/pdf'
                  className={classes.activePdf}
                ></object>
              ) : (
                <img src={activeMedia.url} className={classes.activeImage} />
              )}
            </Grid>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className={classes.bottomContainer}
        >
          <div
            className={classes.mediaList}
          >
            {medias.map((media: PropertyImages, index: number) => (
              <div
                role='button'
                key={index} onClick={() => {
                  setActiveMedia(media);
                  setActiveMediaIndex(index + 1);
                }} className={`${classes.mediaContainer} ${activeMedia.id === media?.id ? classes.borderedImage : classes.borderlessImage}`}>
                {/* <img src={media?.url} className={classes.imageList} /> */}
                <div
                  style={{ backgroundImage: `url('${media?.url}')` }}
                  className={classes.imageList}
                >{
                    getOverlayText(media) &&
                    <span className={classes.overlay}>{getOverlayText(media)}</span>
                  }</div>
              </div>
            ))}
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Gallery;
