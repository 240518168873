import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@emotion/core';
// eslint-disable-next-line import/no-unresolved
import { InteractiveApp, PDFViewer } from '@brokerhub/deliverable-lib';
import BHSCAnalytics from 'libs/@brokerhub/deliverable-lib/src/services/BHSCAnalytics';
import loadAnalytics from 'libs/@brokerhub/deliverable-lib/src/utilities/loadAnalytics';

export function App() {
  const [isUserIdentified, setIsUserIdentified] = useState(false);
  if (!isUserIdentified) {
    loadAnalytics();
    BHSCAnalytics.identifyUser();
    setIsUserIdentified(true);
  }

  return (
    <Router>
      <Switch>
        <Route
          path="/pdf/:encodedUrl"
          render={(props) => <PDFViewer {...props}></PDFViewer>}
        ></Route>
        <Route path="/:id">
          <InteractiveApp />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
