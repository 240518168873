import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';
import { Typography } from '@cbreone/core-ui';

const useStyles = makeStyles((theme: Theme) => createStyles({
  header: {
    backgroundColor: theme.palette.grey['400'],
    display: 'flex',
    padding: '29px',
    width: '100%',
  },
  headerText: {
    color: theme.palette.primary.main,
  },
  headerSecondaryText: { // One Off Font Style
    fontFamily: 'Calibre-R',
    fontSize: '24px',
    letterSpacing: '-0.01em',
    lineHeight: '32px',
  },
  order: {
    color: theme.palette.secondary.light,
    display: 'flex',
  },
  verticalLine: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: '65px',
    marginLeft: '5.5px',
    marginRight: '10px',
  },
}));

const Header: React.FC<Props> = ({
  order,
  title,
  subtitle,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.header}>
      {order && (
        <div className={classes.order}>
          <Typography className={classes.headerSecondaryText}>{order}</Typography>
          <div className={classes.verticalLine} />
        </div>
      )}
      <div className={classes.headerText}>
        <Typography variant="h5">{title}</Typography>
        {subtitle && (<Typography variant="body2">{subtitle}</Typography>)}
      </div>
    </div>
  );
};

export type Props = {
  order?: string;
  subtitle?: string;
  title?: string;
}

export default Header;
