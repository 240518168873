import React from 'react';

type Props = {};
const CbreLogo: React.FC<Props> = () => (
  <>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="104"
      height="20"
      viewBox="0 0 104 24"
    >
      <defs>
        <path
          id="a"
          d="M69.934 5H61v4h8.934C71.05 9 71.897 8.094 72 7.292V6.76A2.077 2.077 0 0 0 69.934 5zM43.151 9C44.205 8.889 45 7.967 45 6.975c0-.993-.795-1.823-1.797-1.975H34l.103 4h9.048zm-.342 9c1.237 0 2.201-.91 2.19-2.02 0-1.02-.849-1.88-1.981-1.98H34v4h8.809zM41.26 0c7.09 0 9.477 2.753 9.477 5.975 0 2.095-.566 4.648-3.833 5.666.011.004 4.086 1.332 4.096 5.466C51 20.53 48.36 24 41.21 24H28V0h13.26zm-17.3 0v5.456h-.203c-.1 0-9.781-.1-11.54.1-3.718.459-6.103 2.913-6.103 6.235 0 2.653 1.657 5.925 6.316 6.334 2.89.25 11.277.1 11.378.1H24V24h-7.862l-2.072-.05c-3.83-.2-5.689-.818-6.932-1.326C3.516 20.998.98 17.985.202 14.354.051 13.586 0 12.768 0 11.95c0-2.603.88-5.057 2.486-7.152C3.779 3.162 5.548 1.835 7.458.968 9.519.2 11.54.05 12.783 0H23.96zM104 5.196H87.395v3.872h14.962v5.247H87.395v4.18H104V24H81V0h23v5.196zM68.675 0C73.179 0 77.949 1.065 78 6.328c0 4.22-3.829 5.294-3.829 5.294s3.726 1.074 3.726 4.637V24h-6.182v-6.269c0-1.88-1.515-3.363-3.51-3.413h-6.92V24H55V0h13.675z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <g fill="#fff" mask="url(#b)">
          <path d="M0-42h200v200H0z" />
        </g>
      </g>
    </svg>
  </>
);

CbreLogo.displayName = 'CbreLogo';
export default CbreLogo;
