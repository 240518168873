import React, { useEffect, useContext, useState, useReducer } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { externalTheme } from '@cbreone/core-ui';
import ButtonBase from '@material-ui/core/ButtonBase';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Chip, FormGroup, Switch, FormControlLabel, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import { InteractiveProperty } from '../../types/interactive/property';
import { Property, PropertyPatch } from '../../types';
import * as mapUtil from '../../utilities/map';
import { iconColorShortlist } from '../../map/constants/MapStyles';
import useInteractiveAPI from '../../services/interactive/useInteractiveAPI';
import InteractiveSurveyContext from '../../hooks/InteractiveSurveyContext';
import { addElementIdToMUINestedElement } from '../../utilities';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      '& .selected': {
        border: `2px solid ${theme.palette.primary.main}`,
      },
    },
    pageTitle: {
      fontSize: 28,
      lineHeight: 1,
      color: theme.palette.primary.main,
      marginBottom: 12,
      marginTop: -10,
    },
    paper: {
      margin: 'auto',
      minWidth: 450,
      marginBottom: 16,
      height: 145,
      [theme.breakpoints.between('sm', 'md')]: {
        height: 145,
        minWidth: 320,
      },
    },
    paperBox: {
      height: '100%',
    },
    image: {
      width: 180,
      height: '100%',
      [theme.breakpoints.between('sm', 'md')]: {
        width: 160,
      },
    },
    img: {
      margin: 'auto',
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%',
    },
    content: {
      padding: '10px 16px 14px 20px',
      flexWrap: 'nowrap',
      width: 220,
      cursor: 'pointer',
      background: '#FFFFFF',
    },
    title: {
      fontSize: 24,
      color: theme.palette.primary.main,
      whiteSpace: 'break-spaces',
      lineHeight: 1,
      maxHeight: 48,
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 19,
      },
    },
    subTitle: {
      whiteSpace: 'break-spaces',
      lineHeight: 1.43,
      maxHeight: 40,
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      [theme.breakpoints.between('sm', 'md')]: {
        fontSize: 12,
      },
      color: theme.palette.grey['800'],
    },
    fullsizeImg: {
      height: '100%',
      width: '100%',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      cursor: 'pointer',
    },
    serialNumber: {
      display: 'flex',
      backgroundColor: '#0976C3',
      borderRadius: 50,
      width: 24,
      height: 24,
      color: theme.palette.primary.contrastText,
      textAlign: 'center',
      alignItems: 'center',
      fontSize: 12,
      margin: 5,
      border: 2,
      borderColor: theme.palette.primary.contrastText,
      borderStyle: 'solid',
    },
    starIcon: {
      backgroundColor: '#F9DD4B',
    },
    numberBox: {
      margin: 'auto',
    },
    propertyDetails: {
      maxHeight: '124px',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'space-around',
      [theme.breakpoints.only('md')]: {
        maxHeight: '124px',
      },
      [theme.breakpoints.only('sm')]: {
        maxHeight: '124px',
      },
    },
    chip: {
      width: '10.26px',
      height: '7.82px',
      backgroundColor: externalTheme.palette.primary.main,
      color: externalTheme.palette.primary.contrastText,
    },
    shortlisted: {
      textAlign: 'right',
      color: externalTheme.palette.primary.contrastText,
      backgroundColor: externalTheme.palette.primary.main,
      textTransform: 'none',
      width: '82px',
      height: '24px',
      fontSize: '10px',
      fontWeight: 400,
      lineHeight: '16px',
      padding: '0px -8px 0px 0px',
      //  top: '24px',
      '& .MuiChip-label': {
        paddingLeft: '8px',
      },
      '&:focus': {
        backgroundColor: externalTheme.palette.primary.main,
      },
      '&:hover': {
        backgroundColor: externalTheme.palette.primary.main,
      },
    },
    chipShortList: {
      width: '12px',
      height: '12px',
      color: externalTheme.palette.primary.main,
      backgroundColor: '#EBF0EF',
    },
    chipRoot: {
      width: '200px',
      '@media (max-width: 767px)': {
        width: '96px',
        marginBottom: '20px',
      },
    },
    shortlist: {
      textAlign: 'right',
      color: externalTheme.palette.primary.main,
      backgroundColor: '#EBF0EF',
      textTransform: 'none',
      width: '104px',
      height: '24px',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '16px',
      borderRadius: 16,
      //   top: '24px',
      '& .MuiChip-label': {
        paddingLeft: '8px',
      },
      '&:hover': {
        backgroundColor: '#EBF0EF',
      },
      '&:focus': {
        backgroundColor: '#EBF0EF',
      },
    },
    shortListRow: {
      marginTop: '-18px',
      '@media (min-width: 767px)': {
        alignItems: 'center',
      },
      '@media (max-width: 767px)': {
        padding: '8px',
      },
    },
    shortListToggle: {
      color: externalTheme.palette.primary.main,
      '@media (max-width: 767px)': {
        flexDirection: 'column-reverse',
        marginLeft: '0px !important',
      },
    },
    icon: {
      color: externalTheme.palette.primary.main,
      width: '16.67px',
      height: '16.67px',
    },
    customWidth: {
      maxWidth: 200,
      backgroundColor: theme.palette.common.black,
      fontFamily: 'Calibre-R',
      fontSize: '14px',
      lineHeight: '21px',
      alignItems: 'center',
    },
    tipArrow: {
      color: theme.palette.common.black,
    },
    drawerHeader: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }),
);

const InteractivePropertyList: React.FC<Props> = ({
  propertyList,
  cardClicked,
  cardSelected,
  shortListPins,
}) => {
  const { survey } = useContext(InteractiveSurveyContext);
  const { getIconData } = mapUtil.default;
  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
  const [shortlisted, setShortlisted] = useState((localStorage.getItem('shortlisted') === 'true'));
  const [propList, setPropertyList] = useState<Property[]>([]);
  const tooltipTitle = 'Once you begin shortlisting you can filter your view to show only shortlisted properties. You can review these with your brokerage team.';
  const { updateInteractiveProperty } = useInteractiveAPI(survey.id);
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const classes = useStyles();
  let counter = 0;
  const muiSwitchRootElement = document.getElementsByClassName('MuiSwitch-root')[0];
  addElementIdToMUINestedElement(muiSwitchRootElement, 'SC-Shortlist-Filter');

  useEffect(() => {
    if (survey?.properties) {
      const activePropertyList = survey.properties
        .filter((item: Property) => (!item.isHidden &&
          ((shortlisted && item.isClientShortlist) || !shortlisted)));

      setPropertyList(activePropertyList);
      if (activePropertyList.filter((prop) => prop.isClientShortlist).length === 0) {
        setShortlisted(false);
      }
    }
  }, [survey, reducerValue, shortlisted]);

  useEffect(() => {
    window.localStorage.setItem('shortlisted', JSON.stringify(shortlisted));
    shortListPins();
  }, [shortlisted]);

  const toggleShortlisted = () => {
    setShortlisted(!shortlisted);
    forceUpdate();
  };

  const shortListClicked = (e: any, item: any) => {
    e.stopPropagation();
    const prop = propList.find(
      (x: any) => x.id === item.id,
    );
    if (propertyList) {
      // eslint-disable-next-line
      item.isClientShortlist = !item.isClientShortlist;
      updateInteractiveProperty(survey, prop as PropertyPatch, survey.id);
      shortListPins();
    }
    forceUpdate();
  };

  const shortlistedDisabled = () => survey.properties.filter((item) =>
    item.isClientShortlist).length === 0;

  const commaAdder = (item: any) => item?.trim() ? `${item}, ` : '';

  return (
    <>
      <div className={classes.drawerHeader}>
        <Typography variant="h5" className={classes.pageTitle}>
          {`${propList.length} ${propList.length > 1 ? 'Properties' : 'Property'}`}
        </Typography>

        <FormGroup row className={classes.shortListRow}>
          <Tooltip
            open={tooltipIsOpen}
            onOpen={() => setTooltipIsOpen(true)}
            onClose={() => setTooltipIsOpen(false)}
            title={tooltipTitle}
            arrow
            placement="top-start"
            classes={{ tooltip: classes.customWidth, arrow: classes.tipArrow }}>
            <InfoOutlinedIcon
              className={classes.icon}
            /* onClick={() => setTooltipIsOpen(!tooltipIsOpen)} */
            />
          </Tooltip>
          <FormControlLabel
            className={classes.shortListToggle}
            control={
              <div>
                <Switch
                  disabled={shortlistedDisabled()}
                  checked={shortlisted}
                  onChange={toggleShortlisted}
                  color="primary"
                />
              </div>
            }
            label='Shortlisted'
            labelPlacement="start"
          />
        </FormGroup>

      </div>
      <div className={classes.root}>
        {propList?.map((item, index) => {
          const property = item as InteractiveProperty;
          const address = `${commaAdder(property?.address1)}${commaAdder(property?.city)}${commaAdder(property?.state || property?.stateProvince)}${property?.postalCode}`;

          if (!item.isCurrentLocation) {
            counter += 1;
          }
          const countIndicator = property.isCurrentLocation ? (
            <span className={classes.numberBox}>&#9733;</span>) :
            (<span className={classes.numberBox}>{counter}</span>);

          const shortlistMarkup = property.isClientShortlist ?
            <Grid
              container
              justifyContent="flex-end"
            >
              <Chip
                label="Shortlisted"
                size="medium"
                icon={<CheckIcon className={classes.chip} />}
                className={(classes.shortlisted)}
                onClick={(e) => shortListClicked(e, item)}
              />
            </Grid>
            :
            <Grid
              container
              justifyContent="flex-end"
            >
              <Chip
                label="Shortlist"
                size="medium"
                icon={<AddIcon className={classes.chipShortList} />}
                className={classes.shortlist}
                onClick={(e) => shortListClicked(e, item)}
              />
            </Grid>;

          const propertyImage = propertyList.filter((propertyListId) => propertyListId.id === item.id)
            .map((propImage) => propImage.imgUrl);
          return (
            <Paper
              className={classes.paper + (cardSelected === index + 1 ? ' selected' : '')}
              key={index}
              onClick={(e) => cardClicked(index)}
            >
              <Grid container className={classes.paperBox}>
                <Grid item style={{ height: '100%' }}>
                  <ButtonBase
                    className={classes.image}
                  >
                    <div
                      style={{ backgroundImage: `url('${propertyImage}')` }}
                      className={classes.fullsizeImg}
                    >
                      <div
                        className={classes.serialNumber}
                        style={{
                          backgroundColor: property.isClientShortlist ? iconColorShortlist :
                            getIconData(property).iconColor,
                        }}
                      >
                        {countIndicator}
                      </div>
                    </div>
                  </ButtonBase>
                </Grid>
                <Grid item sm container className={classes.content}>
                  <Grid item xs container direction="column" className={classes.propertyDetails}>
                    <Typography
                      variant="subtitle1"
                      className={classes.title}
                    >
                      {property.name}
                    </Typography>
                    <Typography variant="body2" className={classes.subTitle}>
                      {`${address}`}
                    </Typography>
                    {shortlistMarkup}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          );
        })}
      </div>
    </>
  );
};

type Props = {
  propertyList: Property[];
  cardClicked: Function;
  cardSelected: number;
  shortListPins: any;
};

InteractivePropertyList.displayName = 'InteractivePropertyList';
export default InteractivePropertyList;
